import React, { useRef, useEffect, useState, lazy, Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './App.scss'
import Header from './common/components/Header'
import Footer from './common/components/Footer'
import Spinner from './common/components/Spinner'
import Snackbar from './common/components/Snackbar/Snackbar'
import { setSnackbar } from './redux/common'
import whatsAppLogo from './images/whatsApp.png'
import Tooltip from './common/components/Tooltip'

const Home = lazy(() => import('./routes/Home'))
const Collection = lazy(() => import('./routes/Collection'))
const Products = lazy(() => import('./routes/Products'))
const ContactUs = lazy(() => import('./routes/ContactUs'))
const TermsAndConditions = lazy(() => import('./routes/TermsAndConditions'))
const PrivacyPolicy = lazy(() => import('./routes/PrivacyPolicy'))
const AboutUs = lazy(() => import('./routes/AboutUs'))
const Login = lazy(() => import('./routes/Login'))
const Dashboard = lazy(() => import('./routes/Dashboard'))

const App = () => {
  const location = useLocation()

  const whatsAppLogoRef = useRef(null)

  const showSpinner = useSelector((state) => state.common.showSpinner)
  const snackbar = useSelector((state) => state.common.snackbar)

  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(!(location.pathname.includes('kmhm0110') || location.pathname.includes('dashboard')))
  }, [location])

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      {snackbar.type && (
        <Snackbar
          type={snackbar.type}
          value={snackbar.value}
          callback={() => {
            dispatch(setSnackbar(false))
          }}
        />
      )}
      {show && <Header />}
      <main className='main-container'>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about-us' element={<AboutUs />}></Route>
            <Route path='/collection' element={<Collection />}></Route>
            <Route path='/products' element={<Products />}></Route>
            <Route path='/contact-us' element={<ContactUs />}></Route>
            <Route path='/terms-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/kmhm0110' element={<Login />} />
            <Route path='/dashboard/*' element={<Dashboard />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </Suspense>
      </main>
      {show && (
        <React.Fragment>
          <a
            href='https://api.whatsapp.com/send?phone=919820381852&text=&source=&data='
            target='_blank'
            rel='noopener noreferrer'
            className='fixed-logo'
            ref={(node) => {
              whatsAppLogoRef.current = node
            }}
          >
            <Tooltip text='Message Us' reference={whatsAppLogoRef} />
            <img className='width-100' src={whatsAppLogo} alt='whatsApp-logo' />
          </a>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default App
